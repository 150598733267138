import { FC } from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';


export const Loading: FC<{ color?: string } & Omit<CircularProgressProps, 'color'>> = function Loading({ color, sx={}, ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        ...sx,
      }}
      data-cy='loading'
    >
      <CircularProgress sx={{ color }} {...props} />
    </Box>
  );
}
