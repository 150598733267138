import { ComponentType, FC, MouseEvent, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { canonicalLink } from '../../utils/routes';
import { CompactUnfurl, CompactUnfurlProps } from './CompactUnfurl';


export type CompactShareUnfurlProps = {
  ContentLink?: ComponentType<{ children: ReactNode }>,
} & Required<Pick<CompactUnfurlProps, 'share'>> & Omit<CompactUnfurlProps, 'share'>;

export const CompactShareUnfurl: FC<CompactShareUnfurlProps> = function CompactShareUnfurl({ share, ...rest }) {

  const history = useHistory();

  const handleClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(canonicalLink(share?.canonicalContent?._id, share?._id));
  }, [history, share]);

  return (
    <a href={canonicalLink(share?.canonicalContent?._id, share?._id)} onClick={handleClick} style={{ maxWidth: '100%' }}>
      <CompactUnfurl share={share} {...rest} />
    </a>
  );
}
