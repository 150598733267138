import { FC, PropsWithChildren } from "react"
import { Helmet as RealHelmet, HelmetProps } from "react-helmet-async"
import { useIsVisible } from "../hooks/use-is-visible"


export const Helmet: FC<PropsWithChildren<HelmetProps>> = function Helmet(props) {
  const isVisible = useIsVisible();

  if(!isVisible) {
    return null;
  }

  // render RealHelmet even if invisible, otherwise head tags don't seem to be removed
  return (
    <RealHelmet {...props}>
      {props.children}
    </RealHelmet>
  );
}
