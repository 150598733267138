import PropTypes from 'prop-types';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FallbackImage } from '../FallbackImage';


export const Preview = function Preview({ title, description, image, loading, called, sx, onClick=(() => {}) }) {

  const theme = useTheme();

  // return skeleton while loading
  if (!called || loading) {
    title = <Skeleton width="80%" animation={loading ? 'pulse' : false} />;
    description = <Skeleton width="40%" animation={loading ? 'pulse' : false} />;
    image = <Skeleton variant="rectangular" width='100%' height='100%' animation={loading ? 'pulse' : false} />;
  }

  return (
    <Card onClick={ onClick } sx={{ display: 'flex', alignItems: 'center', height: 64, borderRadius: 1, border: `1px solid ${theme.palette.grey['300']}`, ...sx }}>
      <Box sx={{ display: 'flex', overflow: 'hidden', width: '100%' }}>

        <Box sx={{ width: 64, flex: '0 0 auto' }}>
          {image || (
            <FallbackImage color={theme.palette.primary.light} scale={2} style={{ height: '100%' }} />
          )}
        </Box>

        <Box
          sx={{
            px: 1,
            py: 1,
            flex: '1 1 auto',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>

          <Typography variant="body1" sx={{ fontStyle: title ? 'normal' : 'italic', color: 'text.primary' }} noWrap>
            {title || 'Title missing'}
          </Typography>

          <Typography variant="body2" noWrap sx={{ mt: title ? 0.5 : 0, color: 'text.secondary' }}>
            {description}
          </Typography>

        </Box>

      </Box>
    </Card>
  );
}

Preview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.node,
  loading: PropTypes.bool,
  called: PropTypes.bool,
  sx: PropTypes.object,
  onClick: PropTypes.func,
};
