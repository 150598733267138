import { Capacitor } from "@capacitor/core";
import { FC } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";


export const ResolveAppLink: FC = function() {

  const location = useLocation();
  const history = useHistory();
  const [params] = useQueryParams({ 'force-open-app': StringParam, 'token': StringParam });

  return (
    <Route
      render={({ staticContext }) => {
        // No useEffect as it needs to run on server
        if(params['force-open-app'] && params['token'] && !Capacitor.isNativePlatform() ) {
          console.log(`Force open app with token`);
          const appUrl = `icecreamclub:/${location.pathname}?token=${params['token']}`;
          if(staticContext) {
            staticContext.statusCode = 302;
            // @ts-expect-error url is not in the type
            staticContext.url = appUrl;
          } else {
            history.replace(appUrl);
          }
        }
        return null;
      }}
    />
  );
}
