import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { truncate } from '../../utils/string';
import { domain } from '../../utils/url';


export function TitleLink({ title, link, titleSx, linkSx }) {

  title = title ? truncate(title, 16, true) : '';

  return (
    <Box
      component="span"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-flex',
        width: '100%'
      }}
    >
      <Box
        component="span"
        sx={{
          color: 'text.secondary',
          ...linkSx
      }}>
        {domain(link)}
      </Box>
      <Box
        component="span"
        sx={{
          color: 'text.secondary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...titleSx
        }}
      >
        {title ? <>&nbsp;•&nbsp;{'"'}{title}{'"'}</> : ''}
      </Box>
    </Box>
  );
}

TitleLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  titleSx: PropTypes.object,
  linkSx: PropTypes.object,
};
