import { FC } from "react";
import { Rating } from "../generated/graphql";
import { RatingIcon } from "./icons/RatingIcon";
import { AddCircleFilled, CheckCircleFilled } from "./icons";
import { CheckCircleHalf } from "./icons/CheckCircleHalf";


export type ItemStateProps = {
  listCreatedBySelf?: boolean,
  isInWatchlist: boolean,
  watched: number,
  rating: Pick<Rating, 'rating'> | null | undefined,
}


export const ItemState: FC<ItemStateProps> = function ItemState({ listCreatedBySelf, isInWatchlist, watched, rating }) {

  if(rating) {
    return <RatingIcon value={rating.rating} highlight={false} style={{ flex: '0 0 auto', marginRight: 4 }} />;
  } else if(!listCreatedBySelf && isInWatchlist && watched === 0 && !rating) {
    return <AddCircleFilled sx={{ height: 16, width: 16, mr: 0.5 }} />;
  } else if(watched > 0 && watched < 1 && !rating) {
    return <CheckCircleHalf sx={{ height: 16, width: 16, mr: 0.5 }} />;
  } else if(watched === 1 && !rating) {
    return <CheckCircleFilled sx={{ height: 16, width: 16, mr: 0.5 }} />;
  }

  return null;
}
