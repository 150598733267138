import { Box, CircularProgress } from '@mui/material';

export function LoadingMore(props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
      data-cy='loading-more'
    >
        <CircularProgress {...props} />
    </Box>
  );
}
