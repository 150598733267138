import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useRemoveParams, StringParam } from '../hooks/remove-params';
import { useContact } from '../hooks/use-contact';
import { FOLLOW_CONTACT } from '../queries/profile';
import { GET_FOLLOWS } from '../queries/user';
import { useSession } from '../hooks/auth';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';


export function ProcessFollowLink() {
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalyticsQueued();
  const { user } = useSession();

  const [ removableParams, removeParams ] = useRemoveParams({ 'follow_contact': StringParam });
  const follow_contact = removableParams?.follow_contact;

  const [followContact] = useMutation(FOLLOW_CONTACT);
  const { data: contactData } = useContact(follow_contact);
  const contact = contactData?.contact;

  useEffect(() => {
    if (user && contact) {
      if(user._id !== contact._id) {
        track('follow_link', { category: 'link' });
        enqueueSnackbar(`Started following ${contact.name}`, { autoHideDuration: 6000 });
        followContact({
          variables: {
            contactId: contact._id,
            follow: true,
          },
          refetchQueries: [{ query: GET_FOLLOWS }],
          update(cache) {
            const userData = cache.readQuery({ query: GET_FOLLOWS });
            let updatedUserData = null;
            if(userData) {
              updatedUserData = {
                currentUser: {
                  ...userData.currentUser,
                  follows: [...userData.currentUser.follows, contact],
                  followSuggestions: userData.currentUser.followSuggestions.filter(c => c._id !== contact._id),
                }
              };
            } else {
              updatedUserData = {
                currentUser: {
                  _id: user._id,
                  __typename: 'Person',
                  follows: [contact],
                  followSuggestions: [],
                }
              };
            }
            cache.writeQuery({ query: GET_FOLLOWS, data: updatedUserData });
          },
        });
      }
      removeParams([ 'follow_contact' ]);
    }
  }, [ contact, removeParams, followContact, user, enqueueSnackbar, track ]);

  return null;
}
