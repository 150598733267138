import { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import { useResolveShortLinkLazyQuery } from "../generated/graphql";


export const ResolveShortLink: FC = function() {

  const location = useLocation();
  const history = useHistory();

  const [resolveShortLink, { data, error }] = useResolveShortLinkLazyQuery();

  useEffect(() => {
    if(error) {
      console.error(`Failed to resolve short link, ignoring and removing.`, error);
      const searchParams = new URLSearchParams(location.search);
      const pathParts = location.pathname.split('/');

      if(pathParts.length > 2 && pathParts[1] === 's') {
        // if short path url, redirect to /
        history.replace('/');
      } else if(searchParams.has('s')) {
         // if query param s, remove it
        searchParams.delete('s');
        history.replace(`${location.pathname}?${searchParams.toString()}`);
      }

    }
  }, [error, history, location.pathname, location.search]);

  useEffect(() => {
    if(data?.resolveShortLink?.fullLink) {
      console.log(`Resolved short link, redirecting`, { location: history.location, fullLink: data.resolveShortLink.fullLink });
      const url = new URL(data.resolveShortLink.fullLink);
      history.replace(`${url.pathname}${url.search}`);
    }
  }, [data, history]);

  useEffect(() => {
    // resolve link if has query param s
    if(location.search) {
      const searchParams = new URLSearchParams(location.search);
      const shortToken = searchParams.get('s');
      if(shortToken) {
        resolveShortLink({ variables: { input: { shortToken } } });
      }
    }

    // resolve link if starts with /s/
    const path = location.pathname.split('/');
    if(path.length > 2 && path[1] === 's') {
      const shortToken = path[2];
      resolveShortLink({ variables: { input: { shortToken } } });
    }
  }, [location.search, location.pathname, resolveShortLink]);

  return null;
}
